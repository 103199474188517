'use client';

import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react';

import { DisplayConceptDef, DisplayConceptRef } from '@lib/api';

// This allows the page to set a "current topic" which shows up in the header and
// tweaks the links in the navbar (to allow continuity in navigation).
//
// Example pattern for use in a page:
//
// const MyComponent: React.FC = ({params}) => {
//   const [cdef_status, cdef] = useGetCDef(decodeURIComponent(params.topic) ?? '');
//   const { topic, setTopicFromCDef } = useTopicContext();
//   useEffect(() => { setTopicFromCDef(cdef); }, [cdef, setTopicFromCDef]);
//   ...
//
// TODO(1): We've backed off from having a current topic throughout the app, so
// we should probably rip this out of most if not all pages.

type TopicContextType = {
  topic: DisplayConceptRef | null;
  setTopic: (topic: DisplayConceptRef | null) => void;
  setTopicFromCDef: (cdef: DisplayConceptDef | null) => void;
  announceTopicChange: (topic: DisplayConceptRef | null) => void;
};

const TopicContext = createContext<TopicContextType | undefined>(undefined);

const useTopicState = (): TopicContextType => {
  const [topic, setTopicState] = useState<DisplayConceptRef | null>(null);

  // hmmm... do we want this in addition to the state?
  const topicRef = useRef<DisplayConceptRef | null>(null);

  const setTopic = useCallback((newTopic: DisplayConceptRef | null) => {
    if (JSON.stringify(newTopic) !== JSON.stringify(topicRef.current)) {
      console.log('TopicProvider: Setting topic to ', newTopic);
      topicRef.current = newTopic;
      setTopicState(newTopic);
    }
  }, []);

  // Convenience function; some pages may load a CDef anyway and this makes
  // it easier to set the global topic from that.
  const setTopicFromCDef = useCallback(
    (cdef: DisplayConceptDef | null) => {
      if (cdef) {
        // hmm... we shouldn't be creating DCRef's, since we don't get overviews...
        // why are we doing this?
        const dcref: DisplayConceptRef = {
          cref: cdef.key,
          display: cdef.display_name,
          description: '',
        };
        setTopic(dcref);
      } else {
        setTopic(null);
      }
    },
    [setTopic]
  );

  const announceTopicChange = useCallback((newTopic: DisplayConceptRef | null) => {
    const message = newTopic ? `Current topic: ${newTopic.display}` : 'No topic selected';
    if (typeof window !== 'undefined') {
      const announcement = document.createElement('div');
      announcement.setAttribute('aria-live', 'polite');
      announcement.setAttribute('aria-atomic', 'true');
      announcement.classList.add('sr-only');
      announcement.textContent = message;
      document.body.appendChild(announcement);
      setTimeout(() => document.body.removeChild(announcement), 1000);
    }
  }, []);

  useEffect(() => {
    announceTopicChange(topic);
  }, [topic, announceTopicChange]);

  return { topic, setTopic, setTopicFromCDef, announceTopicChange };
};

export const TopicProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const topicState = useTopicState();

  return <TopicContext.Provider value={topicState}>{children}</TopicContext.Provider>;
};

export const useTopicContext = (): TopicContextType => {
  const context = useContext(TopicContext);
  if (context === undefined) {
    throw new Error('useTopicContext must be used within a TopicProvider');
  }
  return context;
};
